import { backendAPI } from 'api';
import {
  BIDDING_CONTROL,
  MEDIA,
  STRATEGY_ONE,
  STRATEGY_ONE_K,
} from 'containers/AdsPilot/constants';
import { call, put, select } from 'redux-saga/effects';
import numeral from 'numeral';
import flow from 'lodash/flow';
import { fbacIRI, projectIRI } from 'common/resource-wrappers';
import { launchAdsSuccess, launchAdsFailed } from '../actions';
import { getSelectedThumbnailByAdSetAndVideo } from '../reducer';

export default function* handleLaunchAds({ payload }) {
  const API_BY_STRATEGY = {
    [STRATEGY_ONE]: backendAPI.launchStrategyOne,
    [STRATEGY_ONE_K]: backendAPI.launchStrategyOneThousand,
  };

  try {
    const {
      strategy,
      project,
      data,
      fbAdsAccountId,
      dailyBudget,
      campaignStatusActive,
      audienceSize,
      adSetBudget,
      schedule,
      mode,
      lookalikeCode,
      cboLookalikeCode,
      iteration,
    } = payload;

    const getThumbnail = yield select(getSelectedThumbnailByAdSetAndVideo);

    let creatives;

    switch (strategy) {
      case STRATEGY_ONE:
        creatives = buildMedia(creativesTransformation(data), getThumbnail);
        break;
      case STRATEGY_ONE_K:
        creatives = buildMedia(creativesTransformation(data), getThumbnail);
        break;
      default:
        throw new Error('Unknown strategy');
    }

    const adsData = {
      project: projectIRI(project.uuid),
      facebookAdsAccount: fbacIRI(fbAdsAccountId),
      creatives,
      dailyBudget: numeral(dailyBudget).value(),
      campaignStatusActive,
      audienceSize: numeral(audienceSize).value(),
      adSetBudget,
      schedule,
      mode,
      lookalikeCode,
      cboLookalikeCode,
      iteration,
    };

    const response = yield call(API_BY_STRATEGY[strategy], adsData);

    yield put(launchAdsSuccess(response));
  } catch (e) {
    yield put(launchAdsFailed(e));
  }
}

const biddingControlToNumber = data =>
  data.map(item => ({
    ...item,
    [BIDDING_CONTROL]: numeral(item[BIDDING_CONTROL]).value(),
  }));

const creativesTransformation = flow([biddingControlToNumber]);

const buildMedia = (creatives, getThumbnail) =>
  creatives.map((creative, index) => {
    const media = creative[MEDIA].map(mediaId => {
      const thumbnail = getThumbnail(index, mediaId);
      return thumbnail
        ? { mediaObject: mediaId, thumbnail }
        : { mediaObject: mediaId };
    });

    return { ...creative, [MEDIA]: media };
  });
